import { graphql } from "gatsby"
import { useContext, useState } from "react"
import ThemeContext from "../../context/ThemeContext"
import Layout from "../../components/layout"
import React from "react"
import styles from "./styles.module.scss"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Tab, Tabs, Typography } from "@material-ui/core"
import SEO from "../../components/seo"
import CasinoHelper from "../../services/casino-bonus-helpers"
import RichText from "../../components/text-helpers/rich-text"
import TableOfContent from "../../components/table-of-content/table-of-content"
import MainSearch from "../../components/main-search/main-search"
import t from "../../services/GetTranslationValue"
import ShowMoreText from "react-show-more-text"
import localize from "../../components/localize"
import { localizeItem } from "../../graphql-assistant/createLocaleTextGetter"
import BlockContent from "../../components/text-helpers/portable-text"

const ContentSection = ({ locale, casinos, title, text }) => {
  return (
    [<p>{text.combinedBody && documentToReactComponents(text.combinedBody.json)}</p>,

    <MainSearch forceTopBonus={true} locale={locale} bonuses={casinos} />,]
  )
}

const Content = ({ locale, currentText, previousText, beforeThatText,biggestBonuses, twoHundredBonuses, oneHundredBonuses,oneFiftyBonuses, fiftyBonuses, fiftyBonusText, oneFiftyText }) => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`horizontal-tabpanel-${index}`}
        aria-labelledby={`horizontal-tab-${index}`}
        {...other}
      >
        {children}
      </Typography>
    )
  }

  const defaultValue = locale == 'enCA' || locale == 'enGB' ? 3 : 0; // for UK and CA set default to 100% (not 500%)
  const [value, setValue] = useState(defaultValue)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function TabsIndex(index) {
    return {
      id: `horizontal-tab-${index}`,
      "aria-controls": `horizontal-tabpanel-${index}`,
    }
  }

  function footerExists() {
    if (currentText._rawFooterText.some(x => x.style === "h2" || x.style === "h3" || x.style === "h4")) {
      return (
        <div className={`${styles.tocParent}`}>
          <TableOfContent pageContext={currentText._rawFooterText} />
        </div>
      )
    }
  }

  const {translations} = useContext(ThemeContext);
  return (
    <div className={`${styles.bonusPageTabsContainer}`}>

      {currentText._rawFooterText ? footerExists() : ""}

      <div className={`${styles.showMoreContainer}`}>
      <ShowMoreText
        keepNewLines={false}
        anchorClass=''
        more={t.get(translations, 'show-more')}
        less={t.get(translations, 'show-less')}>
        { currentText._rawHeaderText && <BlockContent cnInternal={`${styles.bigBonusText}`} blocks={currentText._rawHeaderText}/>}
      </ShowMoreText>
    </div>
      <Tabs
        orientation="horizontal"
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        textColor="primary"
        className={`new-casinos-tabs ${styles.tabs}`}
      >
        <Tab
          label="500%"
          {...TabsIndex(0)}
          className={`${styles.tabTitle}`}
        />
        <Tab
          label="200%"
          {...TabsIndex(1)}
          className={`${styles.tabTitle}`}
        />
        <Tab
          label="150%"
          {...TabsIndex(2)}
          className={`${styles.tabTitle}`}
        />
        <Tab
          label="100%"
          {...TabsIndex(3)}
          className={`${styles.tabTitle}`}
        />

        <Tab
          label="50%"
          {...TabsIndex(3)}
          className={`${styles.tabTitle}`}
        />
      </Tabs>

      <TabPanel
        value={value}
        index={0}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={biggestBonuses} title={"Biggest"} text={currentText} />
        </div>
      </TabPanel>
      <TabPanel
        value={value}
        index={1}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={twoHundredBonuses} title={"200"} text={previousText} />
        </div>
      </TabPanel>
      <TabPanel
        value={value}
        index={2}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={oneFiftyBonuses} title={"150"} text={oneFiftyText} />
        </div>
      </TabPanel>
      <TabPanel
        value={value}
        index={3}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={oneHundredBonuses} title={"100"} text={beforeThatText} />
        </div>
      </TabPanel>

      <TabPanel
        value={value}
        index={4}
        className={`${styles.tabContent}`}
      >
        <div className={`${styles.tabBody}`}>
          <ContentSection locale={locale} casinos={fiftyBonuses} title={"50"} text={fiftyBonusText} />
        </div>
      </TabPanel>
      <div className={`${styles.bigBonusText}`}>{currentText._rawFooterText && <BlockContent blocks={currentText._rawFooterText} />}</div>
    </div>
  )
}

const BigBonusesPage = ({ data, pageContext, location }) => {
  let { translations, bonuses } = useContext(ThemeContext)
  bonuses = bonuses.filter(x => (!x.bonuses || x.bonuses.length === 0) || x.bonuses.some(y => y.isCashable))

  const { locale, alternateLocaleSlugs } = pageContext
  let { currentText, previousText, beforeThatText, fiftyText, oneFiftyText} = localizeItem(data, [locale]);
  let [currentTextNode] = currentText.edges
  let [beforeThatTextNode] = beforeThatText.edges
  let [previousTextNode] = previousText.edges
  let [fiftyTextNode] = fiftyText.edges;
  let [oneFiftyTextNode] = oneFiftyText.edges;

  const biggestBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue >= 200)
    .slice()
    .sort((a,b) => {
      let leftBonus =   CasinoHelper.GetTopFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetTopFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });

  const twoHundredBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue <= 200 &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue > 150)
    .slice()
    .sort((a,b) => {
      let leftBonus =   CasinoHelper.GetTopFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetTopFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });

  const oneFiftyBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue <= 150 &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue > 100)
    .slice()
    .sort((a,b) => {
      let leftBonus =   CasinoHelper.GetTopFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetTopFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });

  const oneHundredBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue === 100)
    .slice()
    .sort((a,b) => {
      let leftBonus =   CasinoHelper.GetTopFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetTopFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });


  const fiftyBonuses = bonuses
    .filter(x => x.bonuses &&
      CasinoHelper.GetTopFirstDepositBonus(x) &&
      CasinoHelper.GetTopFirstDepositBonus(x).bonusValue === 50)
    .slice()
    .sort((a,b) => {
      let leftBonus =   CasinoHelper.GetTopFirstDepositBonus(a);
      let rightBonus = CasinoHelper.GetTopFirstDepositBonus(b);
      if (!leftBonus || !rightBonus)
        return 0;
      if (rightBonus.bonusValue  > leftBonus.bonusValue)
        return 1;
      if (rightBonus.bonusValue < leftBonus.bonusValue)
        return -1;
      return 0;
    });



  return (
    <Layout  silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} alternateSlug={alternateLocaleSlugs}
        title={
          currentTextNode &&
          currentTextNode.node &&
          currentTextNode.node.seoTitle
        }
        description={
          currentTextNode &&
          currentTextNode.node &&
          currentTextNode.node.seoMeta
        }
      />

       {/* {currentTextNode &&
      currentTextNode.node &&
      currentTextNode.node.heroImage && (
        <Img fluid={currentTextNode.node.heroImage.asset.fluid} />
       )} */}

      {currentTextNode &&
      currentTextNode.node &&
      currentTextNode.node.h1Tag && (
        <h1 className={`${styles.bonusPageMainTitle}`}> {currentTextNode.node.h1Tag} </h1>
      )}

      {currentText &&
      previousText &&
      beforeThatText &&
      currentTextNode &&
      previousTextNode &&
      fiftyTextNode &&
      oneFiftyTextNode &&
      beforeThatTextNode &&
      currentTextNode.node &&
      previousTextNode.node &&
      beforeThatTextNode.node &&
      fiftyTextNode.node &&
      oneFiftyTextNode.node && (
        <Content
          currentText={localizeItem(currentTextNode.node, [locale])}
          beforeThatText={beforeThatTextNode.node}
          previousText={previousTextNode.node}
          fiftyBonusText={fiftyTextNode.node}
          oneFiftyText={oneFiftyTextNode.node}
          translations={translations}
          locale={locale}
          biggestBonuses={biggestBonuses}
          twoHundredBonuses={twoHundredBonuses}
          oneHundredBonuses={oneHundredBonuses}
          oneFiftyBonuses={oneFiftyBonuses}
          fiftyBonuses={fiftyBonuses}
        />
      )}
    </Layout>
  )
}

export default localize(BigBonusesPage)
export const pageQuery = graphql`
  query BigBonusesQuery {
    sanitySilo (_id: {eq: "3aacbadb-2f40-40ba-bcb8-f6deeb25edb4"}){
        _id
        breadcrumbTrail {
            ...LocaleSiloTrails
        }
    }
    currentText: allSanityGenericLandingPage(
      filter: { name: { eq: "Bonuses" }  }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }

    previousText: allSanityGenericLandingPage(
      filter: { name: { eq: "200Bonus" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }

    beforeThatText: allSanityGenericLandingPage(
      filter: { name: { eq: "100Bonus" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }

    oneFiftyText: allSanityGenericLandingPage(
      filter: { name: { eq: "150Bonus" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }

    fiftyText: allSanityGenericLandingPage(
      filter: { name: { eq: "50Bonus" } }
    ) {
      edges {
        node {
          ...GenericLanding
        }
      }
    }
  }
`
